<template>
  <div>PLEASE WAIT</div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {
    this.$vs.loading({});
    //https://localhost:8081/apps/inbox/start-conversation?name=NOMEAQUI&phone=CODPAIS+DDD+NUMERO_TELEFONE&email=EMAILAQUI
    const query = {
      phone: this.$route.query.phone || null,
      email: this.$route.query.email || null,
      name: this.$route.query.name,
    };
    localStorage.setItem("startConversation", JSON.stringify(query));
    this.$router.push({ name: "inbox" });
  },
};
</script>
